import React from "react";

function Dashboard() {
  return (
    <>
      <div className="container-fluid">
        {/*Page Heading */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h6 mb-0 text-gray-800">Dashboard</h1>
        </div>
        {/*Content Row */}
        <div className="row">
          {/*Earnings (Monthly) d Example */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      New Patients
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      18
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-medkit fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Earnings (Monthly)d Example */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Under Observation
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      153
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-hand-holding-medical fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Discharged
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      36
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-thumbs-up fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>

        {/*Activities */}
        <div className="row">
          {/* Area Chart */}
          <div className="col-xl-3 col-lg-7">
            <div className="card shadow mb-4">
              {/* Card Header - Dropdown */}
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Recent Activities
                </h6>
                <div className="col-auto">
                  <i className="fas fa-clock fa-2x text-gray-300"></i>
                </div>
              </div>
              {/* Card Body */}
              <div className="h6 card-body">
                <li>Patient #UHID16687 shifted to ICU</li>
                <li>#UHID169887 discharged</li>
                <li>Recommended urgent heart surgery for #UHID169887</li>
                <li>New patient admitted with critical condition</li>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-7">
            <div className="card shadow mb-4">
              {/* Card Header - Dropdown */}
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Upcoming Appointments
                </h6>
                <div className="col-auto">
                  <i className="fas fa-calendar-check fa-2x text-gray-300"></i>
                </div>
              </div>
              {/* Card Body */}
              <div className="h6 card-body">
                <u>
                  <li>Scheduled surgery for #UHID16687 on 18 Sep 2023</li>
                  <li>Scheduled heart surgery for #UHID16687 on 18 Sep 2023</li>
                  <li>Scheduled surgery for #UHID16687 on 18 Sep 2023</li>
                  <li>Scheduled heart surgery for #UHID16687 on 18 Sep 2023</li>
                </u>
              </div>
            </div>
          </div>
        </div>

        {/*Activities End */}
      </div>
    </>
  );
}
export default Dashboard;
