import React from "react";
import Patient from "./Patient";
import { NavLink, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";

export default function Mainmenu() {
  return (
    <>
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="."
        >
          <div className="sidebar-brand-icon"><i className="fas fa-user-md"></i></div>
          <div className="sidebar-brand-text mx-3">Doctor</div>
        </a>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item">
          <NavLink to="/" className="nav-link">
            <span>
              <i className="fas fa-fw fa-tachometer-alt"></i>Dashboard
            </span>
          </NavLink>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Main</div>
        {/* Patient */}
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <i className="fas fa-wheelchair"></i>
            <span>Patient</span>
          </a>
          <div
            id="collapseOne"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
                <NavLink to="/patientsearch" className="collapse-item">
                  <i className=""></i>Search
                </NavLink>
                <NavLink to="/patientadd" className="collapse-item">
                  Add
                </NavLink>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-users"></i>
            <span>Users</span>
          </a>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <NavLink to="/usersearch" className="collapse-item">
                Search
              </NavLink>
              <NavLink to="/useradd" className="collapse-item">
                Add
              </NavLink>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i className="fas fa-cog"></i>
            <span>Masters</span>
          </a>
          <div
            id="collapseUtilities"
            className="collapse"
            aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <a className="collapse-item" href="#">
                Medical Test
              </a>
              <a className="collapse-item" href="#">
                Test Parameter
              </a>
              <a className="collapse-item" href="#">
                Medical Staff
              </a>
            </div>
          </div>
        </li>
        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Research</div>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapsePages"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-folder"></i>
            <span>Reports</span>
          </a>
          <div
            id="collapsePages"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Reports</h6>
              <a className="collapse-item" href="login.html">
                Patient History
              </a>
              <a className="collapse-item" href="register.html">
                Medical Case
              </a>
              <div className="collapse-divider"></div>
              <h6 className="collapse-header">Research</h6>
              <a className="collapse-item" href="404.html">
                Research
              </a>
              <a className="collapse-item" href="blank.html">
                Survey
              </a>
            </div>
          </div>
        </li>
        <hr className="sidebar-divider d-none d-md-block" />

        <div className="text-center d-none d-md-inline">
          {/*<button className="rounded-circle border-0" id="sidebarToggle" title="Toggle" />*/}
        </div>
      </ul>
    </>
  );
}
