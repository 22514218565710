import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

export default class Patientsearch extends Component {
  render() {
    return (
      <div>
        <div className="col-xl-6 mx-auto">
          {/*<!-- Account details card-->*/}
          <div className="card mb-4">
            <div className="card-header">
              <i className="fas fa-search"></i>&nbsp;Search Patient
            </div>
            <div className="card-body">
              <form>
                {/*<!-- Form Row-->*/}
                <div className="mb-3">
                  <label className="small mb-1" for="inputUhid">
                    #UHID
                  </label>
                  <input
                    className="form-control"
                    id="inputUhid"
                    type="uhid"
                    placeholder="UHID"
                  />
                </div>
                <div className="row gx-3 mb-3">
                  {/*<!-- Form Group (first name)-->*/}
                  <div className="col-md-4">
                    <label className="small mb-1" for="inputFirstName">
                      First Name
                    </label>
                    <input
                      className="form-control"
                      id="inputFirstName"
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" for="inputMidName">
                      Middle Name
                    </label>
                    <input
                      className="form-control"
                      id="inputMidName"
                      type="text"
                      placeholder="Middle Name"
                    />
                  </div>
                  {/*<!-- Form Group (last name)-->*/}
                  <div className="col-md-4">
                    <label className="small mb-1" for="inputLastName">
                      Last Name
                    </label>
                    <input
                      className="form-control"
                      id="inputLastName"
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                {/*<!-- Form Group (email address)-->*/}
                <div className="row gx-3 mb-3">
                  <div className="col-md-4">
                    <label className="small mb-1" for="inputMobile">
                      Mobile
                    </label>
                    <input
                      className="form-control"
                      id="inputMobile"
                      type="mobile"
                      placeholder="Mobile"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" for="inputAltmobile">
                      Alternate Mobile
                    </label>
                    <input
                      className="form-control"
                      id="inputAltmobile"
                      type="altmobile"
                      placeholder="Alternate Mobile"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" for="inputEmailAddress">
                      Email
                    </label>
                    <input
                      className="form-control"
                      id="inputEmailAddress"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                {/*<!-- Form Group (Roles)-->*/}
                {/*<!-- Submit button-->*/}
                <button className="btn btn-primary" type="button">
                  Search&nbsp;<i className="fas fa-search"></i>&nbsp;
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* Search Result */}
        <div className="col-xl-6 mx-auto">
          {/*<!-- Account details card-->*/}
          <div className="card mb-4">
            <div className="card-header">
              <i className="fas fa-filter"></i>&nbsp;Patient
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#UHID</th>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Case Type</th>
                                <th>Status</th>
                                <th>Next Cons. Date</th>
                                <th>&nbsp;</th>                                            
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>#UHID</th>                                
                                <th>Name</th>
                                <th>Age</th>
                                <th>Case Type</th>
                                <th>Status</th>
                                <th>Next Cons. Date</th>
                                <th>&nbsp;</th>                                            
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr>
                                <td>UHID001</td>
                                <td>Tiger Nixon</td>
                                <td>67</td>
                                <td>Renal</td>
                                <td>Under Obs</td>
                                <td>2011/04/25</td>
                                <td><NavLink to="/patientadd" className="nav-link"><i className="fas fa-edit" /></NavLink>&nbsp;<NavLink><i className="fas fa-trash-alt" /></NavLink></td>
                            </tr>
                            <tr>
                                <td>UHID002</td>
                                <td>Garrett Winters</td>
                                <td>70</td>
                                <td>Cardiac</td>
                                <td>Discharged</td>
                                <td>2011/07/25</td>
                                <td><NavLink to="/patientadd" className="nav-link"><i className="fas fa-edit" /></NavLink>&nbsp;<NavLink><i className="fas fa-trash-alt" /></NavLink> </td>
                            </tr>
                            <tr>
                                <td>UHID003</td>
                                <td>Ashton Cox</td>
                                <td>58</td>
                                <td>Pulmo</td>
                                <td>New</td>
                                <td>2009/01/12</td>
                                <td><NavLink to="/patientadd" className="nav-link"><i className="fas fa-edit" /></NavLink>&nbsp;<NavLink><i className="fas fa-trash-alt" /></NavLink> </td>
                            </tr>
                        </tbody>
                    </table>        
                </div>
            </div>
        </div>
      </div>
      </div>      
    );
  }
}