import React, { Component } from "react";

export default class Useradd extends Component {
  render() {
    return (
      <>
        <div className="row container-xl mx-auto">
          <div className="col-xl-4">
            {/* Profile picture card*/}
            <div className="card mb-4 mb-xl-0">
              <div className="card-header">Profile Picture</div>
              <div className="card-body text-center">
                {/* Profile picture image*/}
                <img
                  className="img-profile rounded-circle"
                  src="./img/profile-1.png"
                  alt=""
                />
                {/* Profile picture help block*/}
                <div className="small font-italic text-muted mb-4">
                  JPG or PNG no larger than 5 MB
                </div>
                {/* Profile picture upload button*/}
                <button className="btn btn-primary" type="button">
                  Upload new image
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            {/* Account details card*/}
            <div className="card mb-4">
              <div className="card-header">
                <i className="fas fa-user"></i>&nbsp;User Details
              </div>
              <div className="card-body">
                <form>
                  {/* Form Row*/}
                  <div className="row gx-3 mb-3">
                    <div className="col-md-12">
                      <label className="small mb-1" for="inputUserId">
                        User Id
                      </label>
                      <input
                        className="form-control"
                        id="inputUserId"
                        type="text"
                        placeholder="User Id"
                      />
                    </div>
                  </div>
                  <div className="row gx-3 mb-3">
                    <div className="col-md-12">
                      <label className="small mb-1" for="inputPwd">
                        Password
                      </label>
                      <input
                        className="form-control"
                        id="inputPwd"
                        type="text"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="row gx-3 mb-3">
                    <div className="col-md-12">
                      <label className="small mb-1" for="inputCnPwd">
                        Confirm Password
                      </label>
                      <input
                        className="form-control"
                        id="inputCnPwd"
                        type="text"
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                  <div className="row gx-3 mb-3">
                    <div className="col-md-6">
                    <label className="small mb-1" for="ddlRole">
                      Role
                    </label>
                    <select
                      className="custom-select mr-sm-2"
                      id="ddlRole"
                    >
                      <option selected>Choose...</option>
                      <option value="1">Admin</option>
                      <option value="2">Doctor</option>
                      <option value="3">Assistance Staff</option>                      
                      <option value="3">Patient</option>
                    </select>
                    </div>
                    <div className="col-md-6">
                    <label className="small mb-1" for="ddlDesig">
                      Designation
                    </label>
                    <select
                      className="custom-select mr-sm-2"
                      id="ddlDesig"
                    >
                      <option selected>Choose...</option>
                      <option value="1">Consultant</option>
                      <option value="2">Senior Resident</option>
                      <option value="3">Junior Resident</option>                      
                      <option value="3">Nursing Officer</option>
                      <option value="3">Assistant</option>                      
                    </select>
                    </div>

                  </div>

                  <div className="row gx-3 mb-3">
                    {/* Form Group (first name)*/}
                    <div className="col-md-4">
                      <label className="small mb-1" for="inputFirstName">
                        First Name
                      </label>
                      <input
                        className="form-control"
                        id="inputFirstName"
                        type="text"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" for="inputMidName">
                        Middle Name
                      </label>
                      <input
                        className="form-control"
                        id="inputMidName"
                        type="text"
                        placeholder="Middle Name"
                      />
                    </div>
                    {/* Form Group (last name)*/}
                    <div className="col-md-4">
                      <label className="small mb-1" for="inputLastName">
                        Last Name
                      </label>
                      <input
                        className="form-control"
                        id="inputLastName"
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  {/* Form Group (email address)*/}
                  <div className="row gx-3 mb-3">
                    <div className="col-md-4">
                      <label className="small mb-1" for="inputMobile">
                        Mobile
                      </label>
                      <input
                        className="form-control"
                        id="inputMobile"
                        type="mobile"
                        placeholder="Mobile"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" for="inputAltmobile">
                        Alternate Mobile
                      </label>
                      <input
                        className="form-control"
                        id="inputAltmobile"
                        type="altmobile"
                        placeholder="Alternate Mobile"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" for="inputEmailAddress">
                        Email
                      </label>
                      <input
                        className="form-control"
                        id="inputEmailAddress"
                        type="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  {/* Form Group (Roles)*/}
                  {/* Submit button*/}
                  <button className="btn btn-primary" type="button">
                    Save Changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
