import React, { Component } from "react";

export default class Patientadd extends Component {
  render() {
    return (
      <>
        <div className="col-xl-6 mx-auto">
          <div className="card mb-4">
            <div className="card-header">
              <i className="fas fa-wheelchair"></i>&nbsp;Add Patient
            </div>
            <div className="card-body">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    className="nav-item nav-link active"
                    id="nav-patient-tab"
                    data-toggle="tab"
                    href="#nav-patient"
                    role="tab"
                    aria-controls="nav-patient"
                    aria-selected="true"
                  >
                    <i className="fas fa-wheelchair"></i>&nbsp;Patient
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-consult-tab"
                    data-toggle="tab"
                    href="#nav-consult"
                    role="tab"
                    aria-controls="nav-consult"
                    aria-selected="false"
                  >
                    <i className="fas fa-stethoscope"></i>&nbsp;Consultation
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-medtest-tab"
                    data-toggle="tab"
                    href="#nav-medtest"
                    role="tab"
                    aria-controls="nav-medtest"
                    aria-selected="false"
                  >
                    <i className="fas fa-microscope"></i>&nbsp;Medical Test
                  </a>
                </div>
              </nav>
              <div className="row gx-3 mb-3">&nbsp;</div>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-patient"
                  role="tabpanel"
                  aria-labelledby="nav-patient-tab"
                >
                  <form>
                    {/*<!-- Form Row-->*/}
                    <div className="mb-3">
                      <label className="small mb-1" for="inputUhid">
                        #UHID
                      </label>
                      <input
                        className="form-control"
                        id="inputUhid"
                        type="uhid"
                        placeholder="UHID"
                      />
                    </div>
                    <div className="row gx-3 mb-3">
                      {/*<!-- Form Group (first name)-->*/}
                      <div className="col-md-4">
                        <label className="small mb-1" for="inputFirstName">
                          First Name
                        </label>
                        <input
                          className="form-control"
                          id="inputFirstName"
                          type="text"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" for="inputMidName">
                          Middle Name
                        </label>
                        <input
                          className="form-control"
                          id="inputMidName"
                          type="text"
                          placeholder="Middle Name"
                        />
                      </div>
                      {/*<!-- Form Group (last name)-->*/}
                      <div className="col-md-4">
                        <label className="small mb-1" for="inputLastName">
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          id="inputLastName"
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    {/*<!-- Form Group (email address)-->*/}
                    <div className="row gx-3 mb-3">
                      <div className="col-md-4">
                        <label className="small mb-1" for="inputMobile">
                          Mobile
                        </label>
                        <input
                          className="form-control"
                          id="inputMobile"
                          type="mobile"
                          placeholder="Mobile"
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" for="inputAltmobile">
                          Alternate Mobile
                        </label>
                        <input
                          className="form-control"
                          id="inputAltmobile"
                          type="altmobile"
                          placeholder="Alternate Mobile"
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" for="inputEmailAddress">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="inputEmailAddress"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    {/*<!-- Form Group (Roles)-->*/}
                    {/*<!-- Submit button-->*/}
                    <button id="btn_add_patient"
                      className="btn btn-primary align-center"
                      type="button"
                    >
                      Next
                    </button>
                  </form>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-consult"
                  role="tabpanel"
                  aria-labelledby="nav-consult-tab"
                >
                  <form>
                    <div className="row gx-3 mb-3">
                      {/*<!-- Form Group (first name)-->*/}
                      <div className="col-md-6">
                        <label className="small mb-1" for="inputFrSymptoms">
                          First Observations
                        </label>
                        <textarea
                          className="form-control"
                          id="txtFrSymptoms"
                          rows="6"
                          placeholder="First Observations"
                        ></textarea>
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" for="inputImpress">
                          Impression
                        </label>
                        <textarea
                          className="form-control"
                          id="txtImpress"
                          rows="6"
                          placeholder="Impression"
                        ></textarea>
                      </div>
                    </div>
                    <div className="row gx-3 mb-3">
                      {/*<!-- Form Group (first name)-->*/}
                      <div className="col-md-6">
                        <label className="small mb-1" for="inputAttName">
                          Attendant Name
                        </label>
                        <input
                          className="form-control"
                          id="inputAttName"
                          type="text"
                          placeholder="Attendant Name"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" for="inputAttMobile">
                          Attendant Mobile
                        </label>
                        <input
                          className="form-control"
                          id="inputAttMobile"
                          type="text"
                          placeholder="Attendant Mobile"
                        />
                      </div>
                      {/*<!-- Form Group (last name)-->*/}
                    </div>
                    <div className="row gx-3 mb-3">
                      {/*<!-- Form Group (first name)-->*/}
                      <div className="col-md-6">
                        <label className="small mb-1" for="ddCaseType">
                          Case Type
                        </label>
                        <select className="custom-select mr-sm-2" id="ddCaseType">
                          <option selected>Choose...</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" for="ddStatusType">
                          Status Type
                        </label>
                        <select className="custom-select mr-sm-2" id="ddStatusType">
                          <option selected>Choose...</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="row gx-3 mb-3">
                      {/*<!-- Form Group (first name)-->*/}
                      <div className="col-md-4">
                        <label className="small mb-1" for="ddDoctor">
                          Doctor
                        </label>
                        <select className="custom-select mr-sm-2" id="ddDoctor">
                          <option selected>Choose...</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" for="ddDoctorAsst">
                          Asst. Doctor
                        </label>
                        <select className="custom-select mr-sm-2" id="ddDoctorAsst">
                          <option selected>Choose...</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      {/*<!-- Form Group (last name)-->*/}
                      <div className="col-md-4">
                        <label className="small mb-1" for="inputNxtDate">
                          Next Conslt. Date
                        </label>
                        <input
                          className="form-control"
                          id="inputNxtDate"
                          type="text"
                          placeholder="Next Conslt. Date"
                        />
                      </div>
                    </div>
                    <button id="btn_add_consult"
                      className="btn btn-primary align-center"
                      type="button"
                    >
                      Next
                    </button>
                  </form>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-medtest"
                  role="tabpanel"
                  aria-labelledby="nav-medtest-tab"
                >
                  ...
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
