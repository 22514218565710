import logo from './logo.svg';
import Mainmenu from './components/Mainmenu';
import Topbar from './components/Topbar';
import Dashboard from './components/Dashboard';
import Patient from './components/Patient';
import { NavLink, Route, Routes } from 'react-router-dom'
import Patientadd from './components/Patientadd';
import Patientsearch from './components/Patientsearch';
import Useradd from './components/Useradd';
import Usersearch from './components/Usersearch';
function App() {
  return (
    <>
    <div id="wrapper">
        <Mainmenu/>
        <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
                <Topbar/>
                <Routes>
                    <Route exact path="/" Component={Dashboard}></Route>  
                    <Route exact path="/patient" Component={Patient}></Route>    
                    <Route exact path="/patientadd" Component={Patientadd}></Route>                        
                    <Route exact path="/patientsearch" Component={Patientsearch}></Route> 
                    <Route exact path="/useradd" Component={Useradd}></Route> 
                    <Route exact path="/usersearch" Component={Usersearch}></Route>                     
                </Routes>
            </div>
        </div>
    </div>
    </>
   );
}
export default App;